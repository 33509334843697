import React, { ReactNode } from 'react'
import BlankLayout from 'src/@core/layouts/BlankLayout'
import { useRouter } from 'next/router'
import SvgComponent, { SvgComponentMain } from 'src/views/organization/SvgComponent'

const IndexComponent = () => {
  const router = useRouter()

  const handleButtonClick = () => {
    router.push('/auth')
  }

  const handleDocsClick = () => {
    window.open('https://docs.tria.so', '_blank')
  }
  return (
    <div className='w-[100%] h-screen flex justify-center bg-white'>
      <div className=' h-auto w-[100%]  flex flex-col items-center justify-center'>
      <div className='  z-[-101]  absolute bottom-0  transform rotate-90 '>
        <div className='transform rotate-90' > <div className='transform rotate-90  ' ><SvgComponentMain /></div></div>
        </div>
        {/* Header */}
        <header className='  top-0 fixed sm:w-[90%] w-[100%] md:w-[70%]  p-4 flex flex-col sm:flex-row justify-between items-center'>
          <div className='flex items-center  space-x-2 cursor-pointer'>
            <img src='/images/logos/tria_logo_black.png' alt='Tria Logo' className='h-7' />
            <span className='font-bold text-2xl text-[#101010]'>tria</span>
          </div>
          <div className='flex space-x-4 mt-3 sm:mt-0 '>
            <button
              className='bg-white text-[#101010] flex justify-between sm:gap-2  items-center  text-sm font-semibold border-[1px] border-gray-200 px-4  py-2 transition transform active:scale-95 rounded-md  hover:bg-gray-100'
              onClick={handleDocsClick}
            >
              <img src='/images/logos/copy.svg' alt='' /> Read our documentation
            </button>
            <button
              className='bg-white text-[#101010] flex justify-between gap-2 items-center text-sm font-semibold border-[1px] border-gray-200 px-4 py-2 transition transform rounded-md active:scale-95  hover:bg-gray-100'
              onClick={handleButtonClick}
            >
              <img src='/images/logos/copy.svg' alt='' /> Log in
            </button>
          </div>
        </header>

        {/* Main Content */}
        <main className='  flex flex-col sm:mt-[120px]  w-[93%] mt-[110px] items-center justify-center '>
          <p className='md:text-5xl sm:text-4xl text-3xl font-bold text-center text-[#101010]'>Welcome to Tria</p>
          <p className='text-xl mt-2 font-semibold  text-center text-[#101010]'>Let's get started!</p>
          <p className='text-center mt-5 text-stone-400 '>Tria takes only a few minutes to set up.</p>
          <button
            className='bg-blue-500 text-white px-8 py-3 rounded-[22px] mt-6  shadow-md hover:bg-blue-600'
            onClick={handleButtonClick}
          >
            Get started
          </button>
          <img src='/images/logos/triab2b.png' alt='Tria Logo' className='sm:mt-0 mt-[5rem]  sm:min-w-[1300px]'/>
          <div className='fixed bottom-0 sm:mb-0 mb-2 flex sm:flex-row flex-col  justify-center items-center gap-[1rem] sm:gap-[2rem] md:gap-[3rem] '>
            <div className='justify-center items-center  gap-1 flex'>
              <div className="text-center text-blue-500 text-md font-normal font-['Roboto'] leading-snug">
                <a href='https://tria.so' target='_blank'>
                  tria.so
                </a>
              </div>
              <div className='w-3.5 h-3.5 justify-center items-center flex'>
                <div className='w-3.5 h-3.5 relative'><img src="/images/logos/export.png" alt="" /></div>
              </div>
            </div>
            <div className="text-center text-neutral-400 text-sm font-normal font-['Roboto'] leading-snug">
              2024 © All Rights Reserved. Product of Threely Dimensions Inc.
            </div>
            <div className="text-center text-neutral-400  text-sm font-normal font-['Roboto'] leading-snug">
              <a
                href='https://triahq.notion.site/Tria-Beta-Privacy-Policy-359373dd601045b888c207f255634371?pvs=4'
                target='_blank'
              >
                Privacy Policy
              </a>
            </div>
            <div className="text-center text-neutral-400 text-sm font-normal font-['Roboto'] leading-snug">
              <a
                href='https://triahq.notion.site/Tria-Beta-Terms-of-Use-1d7dfaf5a58f4038beecd1a67f963425?pvs=4'
                target='_blank'
              >
                Terms of Service
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

IndexComponent.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

IndexComponent.guestGuard = true

export default IndexComponent
